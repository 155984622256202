<!-- =========================================================================================
    File Name: VxTimeline.vue
    Description: Timeline Component
    Component Name: VxTimeline
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <ul class="vx-timeline">
    <li v-for="item in data" :key="item.title">
      <div class="timeline-icon" :class="`bg-${item.color}`">
        <feather-icon :icon="item.icon" svgClasses="text-white stroke-current w-5 h-5" />
      </div>
      <vs-button
        v-if="item.url != null"
        color="danger"
        type="line"
        icon-pack="material-icons"
        icon="attach_file"
        size="large"
        @click="openDocument(`${item.url}`)"
      ></vs-button>

      <small class="text-grey activity-e-time">{{ item.time }}</small>
      <p
        class="font-semibold"
        v-if="email == item.user"
      >{{$t(resources.CreatedBy.i18n) || resources.CreatedBy.name}}: {{ item.user }}</p>

      <div class="timeline-info mt-1">
        <p class="font-semibold mb-4">
          {{ $t(resources.Subject.i18n) || resources.Subject.name }}:
          {{ item.title }}
        </p>
        <p class="font-semibold">
          {{ $t(resources.Body.i18n) || resources.Body.name }}:
        </p>
        <span
          v-if="item.type == 1 || item.type == 3 || item.type == 4"
          class="activity-desc"
          v-html="item.desc"
        ></span>
        <span v-else class="activity-desc">{{ item.desc }}</span>
      </div>
    </li>
  </ul>
</template>

<script>
import resources from "@/i18n/resources.js";

export default {
  name: "vx-timeline",
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      resources: resources,
      email: JSON.parse(localStorage.getItem("userInfo")).email,
    };
  },
  methods: {
    openDocument(url) {
      window.open(url, this.$i18n.t("View"));
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/vxTimeline.scss";
</style>
