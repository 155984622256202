var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "vx-timeline" },
    _vm._l(_vm.data, function(item) {
      return _c(
        "li",
        { key: item.title },
        [
          _c(
            "div",
            { staticClass: "timeline-icon", class: "bg-" + item.color },
            [
              _c("feather-icon", {
                attrs: {
                  icon: item.icon,
                  svgClasses: "text-white stroke-current w-5 h-5"
                }
              })
            ],
            1
          ),
          item.url != null
            ? _c("vs-button", {
                attrs: {
                  color: "danger",
                  type: "line",
                  "icon-pack": "material-icons",
                  icon: "attach_file",
                  size: "large"
                },
                on: {
                  click: function($event) {
                    return _vm.openDocument("" + item.url)
                  }
                }
              })
            : _vm._e(),
          _c("small", { staticClass: "text-grey activity-e-time" }, [
            _vm._v(_vm._s(item.time))
          ]),
          _vm.email == item.user
            ? _c("p", { staticClass: "font-semibold" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.CreatedBy.i18n) ||
                      _vm.resources.CreatedBy.name
                  ) +
                    ": " +
                    _vm._s(item.user)
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "timeline-info mt-1" }, [
            _c("p", { staticClass: "font-semibold mb-4" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(_vm.resources.Subject.i18n) ||
                      _vm.resources.Subject.name
                  ) +
                  ":\n        " +
                  _vm._s(item.title) +
                  "\n      "
              )
            ]),
            _c("p", { staticClass: "font-semibold" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(_vm.resources.Body.i18n) || _vm.resources.Body.name
                  ) +
                  ":\n      "
              )
            ]),
            item.type == 1 || item.type == 3 || item.type == 4
              ? _c("span", {
                  staticClass: "activity-desc",
                  domProps: { innerHTML: _vm._s(item.desc) }
                })
              : _c("span", { staticClass: "activity-desc" }, [
                  _vm._v(_vm._s(item.desc))
                ])
          ])
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }